import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";

export interface AdvanceStadisticsData {
  month: string;
  rate: number;
  fee: number;
  winRate: number;
  percent: string;
  loads: number;
}

export interface DashboardData {
  openLoads: number;
  netMargin: number;
  loadVolume: { loadVolume: number; date: string }[];
  grossRevenue: { grossRevenue: number; date: string }[];
}

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  constructor(private http: HttpClient, private globals: Globals) {}

  getDashboard(filters: {
    date: "shDate" | "invoiceDate";
    from?: Date;
    to?: Date;
  }) {
    return this.http.post<DashboardData>(
      `${this.globals.apiUrl}/reports/dashboard`,
      filters
    );
  }

  getCustomers(
    filters: { from?: any; to?: any; date?: string; clients?: any[] },
    limit?: number | string,
    page?: number | string
  ) {
    return this.http.post<
      [
        any[],
        number,
        {
          loads: number;
          open: number;
          delivered: number;
          completed: number;
          rate: number;
          winRate: number;
        }
      ]
    >(`${this.globals.apiUrl}/reports/customers`, filters, {
      params: { limit: limit.toString(), page: page.toString() },
    });
  }

  getCustomersExcel(filters: {
    from?: any;
    to?: any;
    date?: string;
    clients?: any[];
  }) {
    return this.http.post<Blob>(
      `${this.globals.apiUrl}/reports/customers-excel`,
      filters,
      {
        responseType: "blob" as "json",
      }
    );
  }

  getCarriers(
    filters: { from?: any; to?: any; date?: string },
    limit?: number | string,
    page?: number | string
  ) {
    return this.http.post<
      [
        any[],
        number,
        {
          loads: number;
          fee: number;
        }
      ]
    >(`${this.globals.apiUrl}/reports/carriers`, filters, {
      params: { limit: limit.toString(), page: page.toString() },
    });
  }

  getCarriersExcel(filters: { from?: any; to?: any; date?: string }) {
    return this.http.post<Blob>(
      `${this.globals.apiUrl}/reports/carriers-excel`,
      filters,
      {
        responseType: "blob" as "json",
      }
    );
  }

  getDispatchers(
    filters: {
      from?: any;
      to?: any;
      date?: string;
      target: string;
      clients: any[];
      carriers: any[];
    },
    limit?: number | string,
    page?: number | string
  ) {
    return this.http.post<
      [
        any[],
        number,
        {
          loads: number;
          open: number;
          rate: number;
          winRate: number;
        }
      ]
    >(`${this.globals.apiUrl}/reports/dispatchers`, filters, {
      params: { limit: limit.toString(), page: page.toString() },
    });
  }

  getDispatchersExcel(filters: {
    from?: any;
    to?: any;
    date?: string;
    target: string;
    clients: any[];
    carriers: any[];
  }) {
    return this.http.post<Blob>(
      `${this.globals.apiUrl}/reports/dispatchers-excel`,
      filters,
      {
        responseType: "blob" as "json",
      }
    );
  }

  getAdvanceStadistics(filters: {
    date: "shDate" | "date";
    target: "customers" | "carriers" | "dispatchers";
    targetData?: number[];
  }) {
    return this.http.post<AdvanceStadisticsData[]>(
      `${this.globals.apiUrl}/reports/advance-stadistics`,
      filters
    );
  }
}
